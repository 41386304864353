<template>
  <EditDialog width="45%" :isShow.sync="isShow" :isReturn="true" @closeFn="onOuReportDialogClose">
    <div slot="title" class="title">重要活动报告</div>
    <div slot="content" class="content" style="min-height: 480px; padding: 0 20px">
      <el-form
        style="width: 100%"
        :model="outReportData"
        ref="outReportForm"
        label-width="107px"
        label-position="left"
      >
        <el-form-item label="报告类型" prop="type">
          <el-radio-group v-model="outReportData.type" disabled>
            <el-radio label="WRITTEN">书面报告</el-radio>
            <el-radio label="ORAL">口头报告</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          label="活动开始时间"
          style="width: 50%; display: inline-block"
          prop="startDate"
        >
          <el-date-picker
            v-model="outReportData.startDate"
            align="right"
            type="date"
            placeholder="选择日期"
            disabled
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活动结束时间" style="width: 50%; display: inline-block" prop="endDate">
          <el-date-picker
            v-model="outReportData.endDate"
            align="right"
            type="date"
            placeholder="选择日期"
            disabled
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活动地点" prop="place">
          <el-input v-model.trim="outReportData.place" disabled></el-input>
        </el-form-item>
        <el-form-item :error="outReportData.fileErrMsg">
          <template slot="label">
            <template> 附件</template>
          </template>
          <div
            v-for="(n, index) in outReportData.manhourFileList"
            :key="index"
            style="margin-top: 5px"
            class="file-list"
          >
            <a
              class="fj-text file-name"
              target="_blank"
              style="text-decoration: revert; color: #409eff; padding-right: 8px"
              :href="`${filepath}${n.filePath}`"
            >
              {{ n.fileName }}
            </a>
          </div>
        </el-form-item>
        <el-form-item label="活动说明" prop="content">
          <el-input
            type="textarea"
            :autosize="{ minRows: 8, maxRows: 9999 }"
            v-model.trim="outReportData.content"
            readonly
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="footer">
      <el-button type="info" plain @click="onOuReportDialogClose">关闭</el-button>
    </div>
  </EditDialog>
</template>

<script>
export default {
  name: 'OutReportDetail',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    // 数据源
    outReportData: {
      type: Object,
      default: {},
    },

    //显示隐藏
    isOutReportDialogShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isShow: false,
      filepath: process.env.VUE_APP_FILEPATH,
      fields: {
        name: 'fileName',
        path: 'filePath',
        type: 'type',
      },
    }
  },
  watch: {
    isShow: {
      handler(newVal) {
        if (!newVal) {
          this.$emit('update:isOutReportDialogShow', this.isShow)
        }
      },
    },
  },
  created() {
    this.isShow = this.isOutReportDialogShow
  },
  methods: {
    onOuReportDialogClose() {
      this.isShow = false
    },
  },
}
</script>

<style scoped lang="scss">
.upload-btn-box {
  display: flex;
  align-items: center;
}
.icon-out {
  cursor: pointer;
  font-size: 28px;
  padding-left: 10px;
  vertical-align: middle;
}
.file-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .file-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
</style>
